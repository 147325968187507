import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import useSiteMetadata from '@zd/hooks/useSiteMetadata';
import useLocation from '@zd/hooks/useLocation';

const PageSEO = props => {
  const { title, description, thumbnail } = props;

  const siteMetadata = useSiteMetadata();
  const location = useLocation();

  const pageTitle = title
    ? `${title} | ${siteMetadata.title}`
    : `${siteMetadata.title} — ${siteMetadata.slogan}`;
  const pageDescription = description || siteMetadata.description;
  const pageThumbnail = thumbnail || siteMetadata.thumbnail;

  return (
    <Helmet>
      {/* Icon */}
      <link rel="shortcut icon" href={siteMetadata.icon} />
      <link rel="icon" type="image/x-icon" href={siteMetadata.icon} />

      {/* Browser Theme */}
      <meta name="theme-color" content={siteMetadata.themeColor} />

      {/* SEO */}
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content={siteMetadata.keywords} />

      {/* Language */}
      <meta name="language" content={siteMetadata.language} />

      {/* Location */}
      <meta name="og:country-name" content={siteMetadata.country} />
      <meta name="og:locality" content={siteMetadata.city} />

      {/* Email */}
      <meta name="reply-to" content={siteMetadata.email} />
      <meta name="og:email" content={siteMetadata.email} />

      {/* Name */}
      <meta name="author" content={siteMetadata.title} />
      <meta name="copyright" content={siteMetadata.title} />
      <meta name="designer" content={siteMetadata.title} />
      <meta name="owner" content={siteMetadata.title} />
      <meta property="og:site_name" content={siteMetadata.title} />

      {/* Twitter */}
      <meta name="twitter:site" content={`@${siteMetadata.socialUsernames.twitter}`} />
      <meta name="twitter:creator" content={`@${siteMetadata.socialUsernames.twitter}`} />

      {/* Page Title */}
      <title>{pageTitle}</title>
      <meta name="twitter:title" content={pageTitle} />
      <meta property="og:title" content={pageTitle} />
      <meta itemProp="name" content={pageTitle} />

      {/* Page Description */}
      <meta name="description" content={pageDescription} />
      <meta name="summary" content={pageDescription} />
      <meta name="subject" content={pageDescription} />
      <meta name="topic" content={pageDescription} />
      <meta name="abstract" content={pageDescription} />
      <meta name="twitter:description" content={pageDescription} />
      <meta property="og:description" content={pageDescription} />
      <meta itemProp="description" content={pageDescription} />

      {/* Page Thumbnail */}
      <meta name="twitter:image:src" content={pageThumbnail} />
      <meta property="og:image" content={pageThumbnail} />
      <meta itemProp="image" content={pageThumbnail} />

      {/* Page URL */}
      <meta name="url" content={location.href} />
      <meta property="og:url" content={location.href} />
      <link rel="canonical" href={location.href} />
      <link rel="shortlink" href={location.href} />

      {/* Miscellaneous */}
      <meta name="format-detection" content="telephone=no" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

PageSEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string,
};

export default PageSEO;
